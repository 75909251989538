import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  InputBase,
  IconButton,
  gridClasses,
  Grid,
  Link,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../theme";
import { useLocation } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../Components/Popup";
import AddSubDomain from "./AddSubDomain";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import urls from "../config";
import { useAuth } from "../Context/authContext";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useAxios } from "../Context/useAxios";
import { useAlert } from "../Context/useSnackbar";
import DeleteConfirmationDialog from "../Components/DeleteConfirmationDialog";

function Subdomain() {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const rowData = location.state;
  const { user } = useAuth();
  const { axios } = useAxios();
  const [deleteId, setDeleteId] = useState(null);
  const alert = useAlert();

  // Search Filter
  const [searchValue, setSearchValue] = useState("");

  // Subdomains fetching data
  const [subDomainData, setSubDomainData] = useState({});

  //Popup Booleans
  const [openPopup, setOpenPopup] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  //Pagination
  const [pageNo, setPageNo] = useState(1); // Current page number
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(25); // Number of rows per page

  const handleGoBack = () => {
    // Use this if you are using React Router
    navigate(-1);
  };

  useEffect(() => {
    if (!rowData) {
      navigate("/dashboard");
    }
  }, []);

  const ActiveStatusChange = (daysLeft) => {
    if (daysLeft > 0) {
      return (
        <FiberManualRecordIcon
          style={{ color: "32CD32", fontSize: "0.8rem", marginLeft: 4 }}
        />
      );
    } else {
      return (
        <FiberManualRecordIcon
          style={{ color: "red", fontSize: "0.8rem", marginLeft: 4 }}
        />
      );
    }
  };

  const fetchSubdomainData = (search, page, pageSize) => {
    setSearchValue(search);
    axios
      .get(`${urls.domainfilter}`, {
        params: {
          parent_domain_id: rowData.id,
          Filter_Value: search,
          page: page,
          page_size: pageSize,
        },
      })
      .then((response) => {
        let resp = response.data;
        if (resp.status_code === 200) {
          setSubDomainData(resp.result.results);
          setTotalRows(resp.result.count);
          setPageNo(page);
          setPageSize(pageSize);
        } else {
          alert.error(resp.description);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (rowData) {
      fetchSubdomainData(searchValue, pageNo, pageSize);
    }
  }, []);

  const searchChange = (e) => {
    const search = e.target.value;
    fetchSubdomainData(search, pageNo, pageSize);
  };

  const AddDomainHandle = () => {
    setOpenPopup(true);
  };

  const styles = [
    {
      //   >90
      color: "white",
      backgroundColor: "#60C05C",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "600",
    },
    {
      // yellow
      color: "white",
      backgroundColor: "#F8BE34",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "630",
    },
    {
      // orange
      color: "white",
      backgroundColor: "#ffa500",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "630",
    },
    {
      // red
      color: "white",
      backgroundColor: "#ff1a1a",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "630",
    },
    {
      // dark red
      color: "white",
      backgroundColor: "#ff1a1a",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "630",
    },
  ];

  const dateColorChange = (dateToExpire, daysLeft) => {
    const monthsLeft = Math.abs(Math.floor(daysLeft / 30));
    const yearsLeft = Math.abs(Math.floor(daysLeft / 365));
    if (daysLeft > 90) {
      if (daysLeft < 30)
        return (
          <div style={styles[0]}>
            {dateToExpire}
            <br></br>
            {daysLeft} days to go
          </div>
        );

      if (daysLeft >= 30 && daysLeft < 365)
        return (
          <div style={styles[0]}>
            {dateToExpire}
            <br></br>
            {monthsLeft} months to go
          </div>
        );

      if (daysLeft >= 365)
        return (
          <div style={styles[0]}>
            {dateToExpire}
            <br></br>
            {yearsLeft} year to go
          </div>
        );
    } else if (daysLeft <= 90 && daysLeft >= 45) {
      return (
        <div style={styles[1]}>
          {dateToExpire}
          <br></br>
          {daysLeft} days to go
        </div>
      );
    } else if (daysLeft < 45 && daysLeft >= 15) {
      return (
        <div style={styles[2]}>
          {dateToExpire}
          <br></br>
          {daysLeft} days to go
        </div>
      );
    } else if (daysLeft < 15 && daysLeft > 0) {
      return (
        <div style={styles[3]}>
          {dateToExpire}
          <br></br>
          {daysLeft} days to go
        </div>
      );
    } else if (daysLeft === 0) {
      return <div style={styles[4]}>Expiring Today</div>;
    } else if (daysLeft < 0) {
      return <div style={styles[4]}>Expired</div>;
    }
  };

  const boxSX = {
    "&:hover": {
      color: "blue",
    },
  };

  const columns = useMemo(() => [
    {
      field: "status",
      headerName: "",
      minWidth: 8,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        const dateObj = moment(params.row.certificate_expiry_date);
        const daysLeft = dateObj.diff(moment(), "days");
        return ActiveStatusChange(daysLeft, urls);
      },
      flex: 1,
      align: "center",
    },
    {
      field: "name",
      headerName: "Subdomains",
      minWidth: 350,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Link
              href={`${params.row.url}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              sx={boxSX}
            >
              {params.value}
              <ArrowOutwardIcon style={{ fontSize: 12 }} />
            </Link>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "ssl_status",
      headerName: "SSL",
      minWidth: 200,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const dateObj = params.row.certificate_expiry_date;
        if (dateObj) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      field: "certificate_expiry_date",
      headerName: "Certificate Exp Date",
      minWidth: 300,
      renderCell: (params) => {
        if (params.row.certificate_expiry_date === null) {
          return "None";
        } else {
          const dateObj = moment(params.row.certificate_expiry_date);
          const formattedDateTime = dateObj.format("DD-MM-YYYY");
          const daysLeft = dateObj.diff(moment(), "days");
          return dateColorChange(formattedDateTime, daysLeft);
        }
      },
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "certificate_provider",
      headerName: "Certificate Provider",
      minWidth: 280,
      align: "center",
      disableColumnMenu: true,
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.certificate_provider === null) {
          return "None";
        } else {
          return params.row.certificate_provider;
        }
      },
    },
    user
      ? {
          field: "actions",
          headerName: "Actions",
          minWidth: 50,
          align: "center",
          headerAlign: "center",
          disableColumnMenu: true,
          renderCell: (data) => (
            <Box display="flex" justifyContent="space-around">
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteClick(data.id)}
              >
                <Delete style={{ color: "grey" }} />
              </IconButton>
            </Box>
          ),
        }
      : {
          width: 0,
          disableColumnMenu: true,
          sortable: false,
        },
  ]);

  const handleDeleteClick = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleDelete = (subdata) => {
    axios
      .delete(`${urls.domain}?id=${subdata}`)
      .then((response) => {
        let resp = response.data;
        if (resp.status_code === 200) {
          alert.success("Domain deleted successfully");
          fetchSubdomainData(searchValue, pageNo, pageSize);
        } else {
          alert.error(resp.description);
        }
      })
      .catch((error) => {});
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handlePageChange = (params) => {
    fetchSubdomainData(searchValue, params.page + 1, params.pageSize);
  };

  return (
    <Box p={2} mt={-2}>
      <Box display="flex" flexDirection="column">
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          spacing={1}
          mt={1}
        >
          <Grid item lg={0.5} md={0.5} sm={0.5} xs={12}>
            <IconButton aria-label="back" onClick={handleGoBack}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item lg={4} md={6} sm={9} xs={12} display={"flex"}>
            <Grid
              container
              overflow={"auto"}
              flexWrap={"nowrap"}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
              justifyContent={"center"}
              boxShadow={0}
              bgcolor={"#F8FAFC"}
              sx={{ borderWidth: 0 }}
            >
              <Grid item display={"flex"} flexWrap={"nowrap"}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                    minWidth: 130,
                  }}
                >
                  <div style={{ fontSize: 15 }}>Domain:</div>
                  <div style={{ fontSize: 15 }}>Domain Registrar:</div>
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    minWidth: 170,
                  }}
                >
                  <div style={{ fontSize: 14 }}>{rowData?.name}</div>
                  <div style={{ fontSize: 14 }}>{rowData?.registrar}</div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {user ? (
            <Grid item lg={3.5} md={5.5} sm={12} xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <Button
                  size="medium"
                  style={{
                    color: "white",
                    backgroundColor: colors.blueAccent[500],
                    lineHeight: 1.2,
                  }}
                  startIcon={<AddIcon />}
                  onClick={AddDomainHandle}
                >
                  Add New Subdomain
                </Button>
              </Grid>
            </Grid>
          ) : null}
          {user ? (
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <div
                  style={{
                    backgroundColor: "white",
                    boxShadow: "0 0px 4px rgba(0, 0, 0, 0.2)",
                    width: "fit-content",
                    borderRadius: 5,
                  }}
                >
                  <InputBase
                    sx={{ flex: 1, ml: 1, width: "290px" }}
                    placeholder="Search Domain name"
                    value={searchValue}
                    onChange={searchChange}
                  />
                  <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid item lg={7.5} md={5.5} sm={12} xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <div
                  style={{
                    backgroundColor: "white",
                    boxShadow: "0 0px 4px rgba(0, 0, 0, 0.2)",
                    width: "fit-content",
                    borderRadius: 5,
                  }}
                >
                  <InputBase
                    sx={{ flex: 1, ml: 1, width: "290px" }}
                    placeholder="Search Domain name"
                    value={searchValue}
                    onChange={searchChange}
                  />
                  <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* <Divider sx={{ mb: 1 }} /> */}

        <Grid
          m="20px 0 0 0"
          // height="55vh"
          container
        >
          <Box style={{ backgroundColor: "white", width: "100%" }} mt={-1}>
            <DataGrid
              columns={columns}
              rows={subDomainData}
              getRowId={(row) => row.id}
              autoHeight
              pageSizeOptions={[100, 50, 25, 10, 5]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: pageSize,
                    page: pageNo - 1,
                  },
                },
              }}
              rowCount={totalRows}
              paginationMode="server"
              onPaginationModelChange={handlePageChange}
              sx={{
                [`& .${gridClasses.row}`]: {
                  bgcolor: (theme) =>
                    theme.palette.mode === "light" ? grey[200] : grey[900],
                  textAlign: "center",
                },
              }}
            />
          </Box>
        </Grid>
        <Popup
          title="Add Sub Domain"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <AddSubDomain
            setOpenPopup={setOpenPopup}
            fetchSubdomainData={() =>
              fetchSubdomainData(searchValue, pageNo, pageSize)
            }
          />
        </Popup>
        <DeleteConfirmationDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          handleDelete={() => handleDelete(deleteId)}
        />
      </Box>
    </Box>
  );
}

export default Subdomain;
