import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const loggedInData = JSON.parse(localStorage.getItem("userInfo"));

  useLayoutEffect(() => {
    setUser(loggedInData);
    JSON.parse(localStorage.getItem("userInfo"));
    localStorage.setItem("userInfo", JSON.stringify(loggedInData));
  }, []);


  const login = (param) => {
    setUser(param);
    localStorage.setItem("userInfo", JSON.stringify(param));
  };

  const logout = useCallback(() => {
    localStorage.clear();
    setUser(null);
  }, []);

  const actions = useMemo(
    () => ({
      login,
      logout,
      user,
    }),
    [login, logout, user]
  );

  const mainApp = useMemo(
    () => (
      <AuthContext.Provider value={actions}>{children}</AuthContext.Provider>
    ),
    [actions, children]
  );

  return <React.Fragment>{mainApp}</React.Fragment>;
};

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
