import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useTheme,
  InputBase,
  IconButton,
  Divider,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../theme";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../Components/Popup";
import AddDomain from "./AddDomain";
import urls from "../config";
import { useAuth } from "../Context/authContext";
import Table from "../Components/Table";
import { useAxios } from "../Context/useAxios";

function Dashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useAuth();
  const { axios } = useAxios();
  // Search Filter
  const [searchValue, setSearchValue] = useState("");

  // Backend data api
  const [domainData, setDomainData] = useState({});

  //popup
  const [openPopup, setOpenPopup] = useState(false);

  //Pagination
  const [pageNo, setPageNo] = useState(1); // Current page number
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(25); // Number of rows per page

  const fetchData = (search, page, pageSize) => {
    setSearchValue(search);
    axios
      .get(`${urls.domainfilter}`, {
        params: {
          Filter_Value: search,
          page: page,
          page_size: pageSize,
        },
      })
      .then((response) => {
        let resp = response.data;
        if (resp.status_code === 200) {
          setDomainData(resp.result.results);
          setTotalRows(resp.result.count);
          setPageNo(page);
          setPageSize(pageSize);
        } else {
          alert.error(resp.description);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchData(searchValue, pageNo, pageSize);
  }, []);

  const handlePageChange = (params) => {
    fetchData(searchValue, params.page + 1, params.pageSize);
  };

  const searchChange = (e) => {
    const search = e.target.value;
    fetchData(search, pageNo, pageSize);
  };

  const AddDomainHandle = () => {
    setOpenPopup(true);
  };

  return (
    <Box p={2} mt={-2}>
      <Box display="flex" flexDirection="column">
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={"flex-end"}
          spacing={1}
          mt={1}
        >
          {user ? (
            <Grid item lg={8} md={4} sm={4} xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <Button
                  size="medium"
                  style={{
                    color: "white",
                    backgroundColor: colors.blueAccent[500],
                    lineHeight:1.2
                  }}
                  startIcon={<AddIcon />}
                  onClick={AddDomainHandle}
                >
                  Add New Domain
                </Button>
              </Grid>
            </Grid>
          ) : null}

          <Grid item lg={3.7} md={5} sm={8} xs={12}>
            <Grid container justifyContent={"flex-end"}>
              <div
                style={{
                  backgroundColor: "white",
                  boxShadow: "0 0px 4px rgba(0, 0, 0, 0.2)",
                  width: "fit-content",
                  borderRadius: 5,
                  display: "flex",
                }}
              >
                <InputBase
                  sx={{ flex: 1, ml: 1, width: "310px" }}
                  placeholder="Search Domain name"
                  value={searchValue}
                  onChange={searchChange}
                />
                <IconButton type="button" sx={{ p: 1 }}>
                  <SearchIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Domains table starts here */}

      <Box style={{ backgroundColor: "white" }} mt={-1}>
        <Table
          domainData={domainData}
          fetchData={() => fetchData(searchValue, pageNo, pageSize)}
          pageSize={pageSize}
          pageNo={pageNo}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
        />
      </Box>

      {/* ADD domain pop up section starts here */}

      <Popup
        title="Add Domain"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddDomain
          setOpenPopup={setOpenPopup}
          fetchData={() => fetchData(searchValue, pageNo, pageSize)}
        />
      </Popup>
    </Box>
  );
}

export default Dashboard;
