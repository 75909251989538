import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import InputAdornment from "@mui/material/InputAdornment";
import urls from "../config";
import { useAlert } from "../Context/useSnackbar";
import { useAxios } from "../Context/useAxios";

function AddDomain({ fetchData, setOpenPopup }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domainName, setDomainName] = useState("");
  const [domainUrl, setDomainUrl] = useState("");
  const [email, setEmail] = useState("");
  const [domainDetails, setDomainDetails] = useState();
  const [isFetchDetails, setIsFetchDetails] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const { axios } = useAxios();
  const alert = useAlert();

  const fetchDomainDetails = () => {
    setFetchLoading(true);
    axios
      .get(`${urls.fetchdetails}`, {
        params: {
          name: domainName,
        },
      })
      .then((response) => {
        let resp = response.data;
        if (resp.status_code === 200) {
          setDomainDetails(resp.result);
          setIsFetchDetails(true);
          setError("");
          if (resp.result.certificate_expiry_date) {
            setDomainUrl(
              `https://${
                domainName?.startsWith("www.")
                  ? domainName?.replace("www.", "")
                  : domainName
              }`
            );
          } else {
            setDomainUrl(
              `http://${
                domainName?.startsWith("www.")
                  ? domainName?.replace("www.", "")
                  : domainName
              }`
            );
          }
        } else {
          alert.error(resp.description);
        }
        setFetchLoading(false);
      })
      .catch((error) => {
        setFetchLoading(false);
      });
  };

  const handleFetchDomainDetails = () => {
    if (!domainName) {
      setError("Please enter a domain name.");
      return;
    }

    if (!domainName.includes(".")) {
      setError("Please enter a valid domain format (Example.com, Example.in)");
      return;
    }

    fetchDomainDetails();
  };

  function isValidEmail(email) {
    // Regular expression for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const handleAddDomain = () => {
    const name = domainName?.startsWith("www.")
      ? domainName?.replace("www.", "")
      : domainName;
    const url = domainUrl;
    const owner_email = email;
    const registrar = domainDetails?.registrar;
    const created_at = domainDetails?.created_at;
    const modified_at = domainDetails?.modified_at;
    const certificate_expiry_date = domainDetails?.certificate_expiry_date;
    const domain_expiry_date = domainDetails?.domain_expiry_date;
    const certificate_provider = domainDetails?.certificate_provider;

    if (!email) {
      setIsEmail("Please enter a Email ID.");
      return;
    }

    if (!isValidEmail(email)) {
      setIsEmail("Please enter a valid Email ID Format");
      return;
    }

    setSaveLoading(true);
    axios
      .post(`${urls.domain}`, {
        name,
        url,
        registrar,
        owner_email,
        certificate_provider,
        created_at,
        modified_at,
        certificate_expiry_date,
        domain_expiry_date,
      })
      .then((response) => {
        setSaveLoading(false);
        let resp = response.data;
        if (resp.status_code === 200) {
          fetchData();
          setOpenPopup(false);
        } else {
          alert.error(resp.description);
        }
      })
      .catch((error) => {
        setSaveLoading(false);
      });
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Box
        display="flex"
        width="90vw"
        p={2}
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width="90%"
        >
          <Box display="flex" justifyContent="space-between">
            <Box display={"flex"} flexDirection={"column"} width={"100%"}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
                width="100%"
              >
                <TextField
                  id="outlined-basic"
                  name="domainName"
                  className="DomainName"
                  label="DomainName"
                  variant="outlined"
                  placeholder="Domainname: Example.com "
                  size="small"
                  style={{ width: "100%" }}
                  sx={{ mr: 3 }}
                  value={domainName}
                  onChange={(e) => {
                    setDomainName(e.target.value);
                    setIsFetchDetails(false);
                  }}
                />
                <Box backgroundColor={colors.blueAccent[500]} borderRadius={1}>
                  <Button
                    size="medium"
                    onClick={handleFetchDomainDetails}
                    style={{
                      backgroundColor: colors.blueAccent[500],
                      color: "white",
                    }}
                  >
                    {fetchLoading ? (
                      <CircularProgress
                        size={20}
                        style={{ color: "white", marginRight: 5 }}
                      />
                    ) : null}
                    Add
                  </Button>
                </Box>
              </Box>
              {error ? <p style={{ color: "red" }}>{error}</p> : null}
            </Box>
          </Box>

          {isFetchDetails ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="60vh"
              mt={1}
            >
              <Box display="flex" flexDirection="column">
                <label>Domain url</label>
                <TextField
                  id="outlined-basic"
                  name="domainUrl"
                  className="DomainUrl"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">https://</InputAdornment>
                    ),
                  }}
                  size="small"
                  value={domainName}
                  onChange={(e) => setDomainUrl(e.target.value)}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <label>Owner's Email</label>
                <TextField
                  id="my-input"
                  name="owner_email"
                  className="Owner Email"
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              {isEmail ? (
                <p style={{ color: "red", marginTop: -2, marginBottom: -1 }}>
                  {isEmail}
                </p>
              ) : null}
              <Box display="flex" flexDirection="column">
                <label>Registrar</label>
                <TextField
                  id="my-input"
                  InputProps={{
                    readOnly: true,
                  }}
                  name="registrar"
                  className="Registrar"
                  variant="outlined"
                  size="small"
                  value={domainDetails?.registrar}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <Box display="flex" flexDirection="column">
                  <label>Created Date</label>
                  <TextField
                    id="outlined-basic"
                    name="created_at"
                    className="created_at"
                    variant="outlined"
                    size="small"
                    value={domainDetails?.created_at}
                  />
                </Box>
                <Box display="flex" flexDirection="column">
                  <label>Modified Date</label>
                  <TextField
                    id="outlined-basic"
                    name="modified"
                    className="Modified Date"
                    variant="outlined"
                    size="small"
                    value={domainDetails?.modified_at}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <Box display="flex" flexDirection="column">
                  <label>Domain Expiry Date</label>
                  <TextField
                    id="outlined-basic"
                    name="domainExpiryDate"
                    className="domainExpiryDate"
                    variant="outlined"
                    size="small"
                    value={domainDetails?.domain_expiry_date}
                  />
                </Box>
                <Box display="flex" flexDirection="column">
                  <label>Certificate Expiry Date</label>
                  <TextField
                    id="outlined-basic"
                    name="certificateExpiryDate"
                    className="certificateExpiryDate"
                    variant="outlined"
                    size="small"
                    value={domainDetails?.certificate_expiry_date}
                  />
                </Box>
              </Box>
              <Button
                variant="contained"
                onClick={handleAddDomain}
                style={{
                  backgroundColor: colors.blueAccent[500],
                  color: "white",
                }}
                sx={{ mt: 2 }}
              >
                {saveLoading ? (
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginRight: 5 }}
                  />
                ) : null}{" "}
                Add Domain
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
export default AddDomain;
