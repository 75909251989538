let BASE;
let HOST;
let HOSTNAME;
let PORT;
const PROTO = "http";
const PROTO_HTTPS = "https";
console.log(process.env.REACT_APP_UI_ENV, "env");
if (process.env.REACT_APP_UI_ENV === "development") {
  HOST = "domain-dashboard.dev-k8.letseduvate.com"; //api domain
  HOSTNAME = `${HOST}/api`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
} else if (process.env.REACT_APP_UI_ENV === "prod") {
  HOST = "domaindash.letseduvate.com"; //api domain
  HOSTNAME = `${HOST}/api`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
} else {
  // HOST = "localhost";
  // PORT = "8000";
  // HOSTNAME = `${HOST}:${PORT}/api`;
  // BASE = `${PROTO}://${HOSTNAME}`;
  HOST = "domain-dashboard.dev-k8.letseduvate.com"; //api domain
  HOSTNAME = `${HOST}/api`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
}

const urls = {
  domain: `${BASE}/domain/`,
  login: `${BASE}/login/`,
  register: `${BASE}/register/`,
  alldomain: `${BASE}/alldomains/`,
  domainprovider: `${BASE}/domainprovider/`,
  domainfilter: `${BASE}/domainfilter/`,
  employee: `${BASE}/employee/`,
  fetchdetails: `${BASE}/fetchdetails/`,
};
export default urls;
