import React from "react";
import { AuthProvider } from "./Context/authContext";
import "./App.css";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

// Pages
import Routes from "./Routes";
import { AlertProvider } from "./Context/useSnackbar";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="Container">
          <main className="content"></main>
          <AlertProvider>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </AlertProvider>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
