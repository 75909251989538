/* eslint-disable */
import React, { createContext, useContext, useEffect, useMemo } from "react";
import axios from "axios";
import { useAlert } from "./useSnackbar";
import { useAuth } from "./authContext";

const AxiosContext = createContext(null);

const AxiosProvider = ({ children }) => {
  const alert = useAlert();
  const { logout, user } = useAuth();

  axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logout();
      } else {
        try {
          if (error?.response?.data?.message) {
            alert.error(error?.response?.data?.message);
          } else if (error?.response?.data?.description) {
            alert.error(error?.response?.data?.description);
          } else {
            alert.error(`Error fetching data: ${error}`);
          }
        } catch (e) {
          alert.error(`Error fetching data: ${error}`);
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${user?.access_token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
    return () => {
      delete axios.defaults.headers.common["Authorization"];
    };
  }, [user]);

  const actions = useMemo(
    () => ({
      axios,
    }),
    [axios]
  );

  const mainApp = useMemo(
    () => (
      <AxiosContext.Provider value={actions}>{children}</AxiosContext.Provider>
    ),
    [actions, children]
  );

  return <React.Fragment>{mainApp}</React.Fragment>;
};

function useAxios() {
  const context = useContext(AxiosContext);
  if (context === undefined) {
    throw new Error("useAxios must be used within a AxiosProvider");
  }
  return context;
}

export { AxiosProvider, useAxios };
