import React, { useMemo, useState } from "react";
import {
  Box,
  gridClasses,
  Button,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import urls from "../config";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useAuth } from "../Context/authContext";
import { useAxios } from "../Context/useAxios";
import { useAlert } from "../Context/useSnackbar";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";

function Table({
  domainData,
  fetchData,
  pageSize,
  totalRows,
  handlePageChange,
  pageNo,
}) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { axios } = useAxios();
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const alert = useAlert();

  const handleSubdomain = (rowData) => {
    navigate(`/subdomain/`, { state: rowData });
  };

  const ActiveStatusChange = (daysLeft) => {
    if (daysLeft > 0) {
      return (
        <FiberManualRecordIcon
          style={{ color: "32CD32", fontSize: "0.8rem", marginLeft: 4 }}
        />
      );
    } else {
      return (
        <FiberManualRecordIcon
          style={{ color: "red", fontSize: "0.8rem", marginLeft: 4 }}
        />
      );
    }
  };

  const styles = [
    {
      //   >90
      color: "white",
      backgroundColor: "#60C05C",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "600",
    },
    {
      // yellow
      color: "white",
      backgroundColor: "#F8BE34",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "630",
    },
    {
      // orange
      color: "white",
      backgroundColor: "#ffa500",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "630",
    },
    {
      // red
      color: "white",
      backgroundColor: "#ff1a1a",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "630",
    },
    {
      // dark red
      color: "white",
      backgroundColor: "#ff1a1a",
      height: "49px",
      width: "130px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "630",
    },
  ];

  const dateColorChange = (dateToExpire, daysLeft) => {
    const monthsLeft = Math.abs(Math.floor(daysLeft / 30));
    const yearsLeft = Math.abs(Math.floor(daysLeft / 365));
    if (daysLeft > 90) {
      if (daysLeft < 30)
        return (
          <div style={styles[0]}>
            {dateToExpire}
            <br></br>
            {daysLeft} days to go
          </div>
        );

      if (daysLeft >= 30 && daysLeft < 365)
        return (
          <div style={styles[0]}>
            {dateToExpire}
            <br></br>
            {monthsLeft} months to go
          </div>
        );

      if (daysLeft >= 365)
        return (
          <div style={styles[0]}>
            {dateToExpire}
            <br></br>
            {yearsLeft} year to go
          </div>
        );
    } else if (daysLeft <= 90 && daysLeft >= 45) {
      return (
        <div style={styles[1]}>
          {dateToExpire}
          <br></br>
          {daysLeft} days to go
        </div>
      );
    } else if (daysLeft < 45 && daysLeft >= 15) {
      return (
        <div style={styles[2]}>
          {dateToExpire}
          <br></br>
          {daysLeft} days to go
        </div>
      );
    } else if (daysLeft < 15 && daysLeft > 0) {
      return (
        <div style={styles[3]}>
          {dateToExpire}
          <br></br>
          {daysLeft} days to go
        </div>
      );
    } else if (daysLeft === 0) {
      return <div style={styles[4]}>Expiring Today</div>;
    } else if (daysLeft < 0) {
      return <div style={styles[4]}>Expired</div>;
    }
  };

  const boxSX = {
    "&:hover": {
      color: "blue",
    },
  };

  const columns = useMemo(() => [
    {
      field: "status",
      headerName: "",
      minWidth: 15,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        const dateObj = moment(params.row.certificate_expiry_date);
        const daysLeft = dateObj.diff(moment(), "days");
        return ActiveStatusChange(daysLeft, urls);
      },
      flex: 1,
      align: "center",
    },
    {
      field: "name",
      headerName: "Domains",
      minWidth: 230,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Link
              href={`${params.row.url}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              sx={boxSX}
            >
              {params.value}
              <ArrowOutwardIcon style={{ fontSize: 12 }} />
            </Link>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "subdomaincount",
      headerName: "SubDomains",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button
          type="submit"
          onClick={() => handleSubdomain(params.row)}
          color="primary"
          style={{ cursor: "pointer", textDecoration: "none", color: "blue" }}
          title="Click to expand"
        >
          {`${params.row.subdomaincount}`}
        </Button>
      ),
      flex: 1,
    },
    {
      field: "ssl_status",
      headerName: "SSL",
      minWidth: 5,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const dateObj = params.row.certificate_expiry_date;
        if (dateObj) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      field: "certificate_expiry_date",
      headerName: "Certificate Exp Date",
      minWidth: 190,
      headerClassName: "custom-header",
      renderCell: (params) => {
        if (params.row.certificate_expiry_date === null) {
          return "None";
        } else {
          const dateObj = moment(params.row.certificate_expiry_date);
          const formattedDateTime = dateObj.format("DD-MM-YYYY");
          const daysLeft = dateObj.diff(moment(), "days");
          return dateColorChange(formattedDateTime, daysLeft);
        }
      },
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "certificate_provider",
      headerName: "Certificate Provider",
      minWidth: 150,
      align: "center",
      disableColumnMenu: true,
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.certificate_provider === null) {
          return "None";
        } else {
          return params.row.certificate_provider;
        }
      },
    },
    {
      field: "domain_expiry_date",
      headerName: "Domain Exp Date",
      minWidth: 140,
      renderCell: (params) => {
        if (params.row.domain_expiry_date === null) {
          return "None";
        } else {
          const dateObj = moment(params.row.domain_expiry_date);
          const formattedDateTime = dateObj.format("DD-MM-YYYY");
          const daysLeft = dateObj.diff(moment(), "days");
          return dateColorChange(formattedDateTime, daysLeft);
        }
      },
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "registrar",
      headerName: "Registrar",
      minWidth: 200,
      align: "center",
      disableColumnMenu: true,
      headerAlign: "center",
    },
    {
      field: "owner_email",
      headerName: "Owner",
      minWidth: 200,
      align: "center",
      disableColumnMenu: true,
      headerAlign: "center",
    },
    {
      field: "created_at",
      headerName: "Created Date",
      minWidth: 120,
      valueGetter: (params) => {
        const dateObj = moment(params.row.created_at);
        const formattedDateTime = dateObj.format("DD-MM-YYYY");
        return formattedDateTime;
      },
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "modified_at",
      headerName: "Last Renewed",
      minWidth: 120,
      valueGetter: (params) => {
        const dateObj = moment(params.row.modified_at);
        const formattedDateTime = dateObj.format("DD-MM-YYYY");
        return formattedDateTime;
      },
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 1,
    },
    user
      ? {
          field: "actions",
          headerName: "Actions",
          minWidth: 100,
          disableColumnMenu: true,
          renderCell: (data) => (
            <Box display="flex" justifyContent="space-around">
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteClick(data.id)}
              >
                <Delete style={{ color: "grey" }} />
              </IconButton>
            </Box>
          ),
          flex: 1,
        }
      : {
          width: 0,
          disableColumnMenu: true,
          sortable: false,
        },
  ]);

  const handleDeleteClick = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleDelete = (subdata) => {
    axios
      .delete(`${urls.domain}?id=${subdata}`)
      .then((response) => {
        let resp = response.data;
        if (resp.status_code === 200) {
          alert.success("Domain deleted successfully");
          fetchData();
        } else {
          alert.error(resp.description);
        }
      })
      .catch((error) => {});
    setOpenDialog(false);
    setDeleteId(null);
  };

  return (
    <div>
      <Box m="20px 0 0 0" width="100%">
        <DataGrid
          columns={columns}
          rows={domainData}
          getRowId={(row) => row.id}
          autoHeight
          pageSizeOptions={[100, 50, 25, 10, 5]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
                page: pageNo - 1,
              },
            },
            pinnedColumns: { left: ["name"] },
          }}
          rowCount={totalRows}
          paginationMode="server"
          onPaginationModelChange={handlePageChange}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
              textAlign: "center",
            },
          }}
        />
      </Box>
      <DeleteConfirmationDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleDelete={() => handleDelete(deleteId)}
      />
    </div>
  );
}

export default Table;
