import React, { useState, useEffect } from "react";
import { TextField, Grid, Paper, Typography, Divider } from "@mui/material";
import urls from "../config";
import { useAuth } from "../Context/authContext";
import { useAxios } from "../Context/useAxios";
import { useAlert } from "../Context/useSnackbar";

function MyAccount() {
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [erp, setErp] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [employee, setEmployee] = useState();
  const username = localStorage.getItem("username");
  const { axios } = useAxios();
  const alert = useAlert();

  const fetchEmployeeDetails = () => {
    axios
      .get(`${urls.employee}`, {
        params: { username: username },
      })
      .then((response) => {
        let resp = response.data;
        if (resp.status_code === 200) {
          setEmployee(resp.result[0]);
          setName(resp.result[0]?.name);
          setErp(resp.result[0]?.erp_id);
          setEmail(resp.result[0]?.email);
          setPhone(resp.result[0]?.mobile);
        } else {
          alert.error(resp.description);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Paper
        style={{
          padding: "2em",
          minHeight: "70vh",
          minWidth: "30vw",
          marginTop: "2%",
        }}
      >
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item>
            <Typography variant="h4">Profile Details</Typography>
          </Grid>
          <Divider />
          <Grid
            item
            display={"flex"}
            flexDirection={"column"}
            spacing={2}
          >
            <label>Name:</label>
            <TextField
              required
              id="outlined-basic"
              name="username"
              className="Username"
              variant="outlined"
              placeholder="Username"
              size="small"
              inputProps={{ readOnly: true }}
              style={{ width: "100%" }}
              value={name}
            />
          </Grid>
          <Grid item display={"flex"} flexDirection={"column"}>
            <label>ERP ID:</label>
            <TextField
              required
              id="outlined-basic"
              name="username"
              className="Username"
              variant="outlined"
              placeholder="Username"
              size="small"
              style={{ width: "100%" }}
              inputProps={{ readOnly: true }}
              value={erp}
            />
          </Grid>
          <Grid item display={"flex"} flexDirection={"column"}>
            <label>Email ID:</label>
            <TextField
              required
              id="outlined-basic"
              name="username"
              className="Username"
              variant="outlined"
              placeholder="Username"
              size="small"
              inputProps={{ readOnly: true }}
              style={{ width: "100%" }}
              value={email}
            />
          </Grid>
          <Grid item display={"flex"} flexDirection={"column"}>
            <label>Phone Number:</label>
            <TextField
              required
              id="outlined-basic"
              name="username"
              className="Username"
              variant="outlined"
              placeholder="Username"
              size="small"
              inputProps={{ readOnly: true }}
              style={{ width: "100%" }}
              value={phone}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
export default MyAccount;
