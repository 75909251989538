import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

// Pages
import Login from "../Login_auth/Login";
import Dashboard from "../Pages/Dashboard";
import MyAccount from "../Pages/MyAccount";
import Topbar from "../Components/TopBar";
import Subdomain from "../Pages/SubDomain";
import { useAuth } from "../Context/authContext";
import { AxiosProvider } from "../Context/useAxios";

const unAuthosizedRoute = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <Topbar />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/subdomain",
        element: <Subdomain />,
      },
    ],
  },
  {
    path: "*", // Match any other path
    element: <Navigate to="/dashboard" />, // Set the default route to Dashboard
  },
]);

const authosizedRoute = createBrowserRouter([
  {
    element: <Topbar />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/subdomain",
        element: <Subdomain />,
      },
      {
        path: "/myaccount",
        element: <MyAccount />,
      },
      {
        path: "*", // Match any other path
        element: <Navigate to="/dashboard" />, // Set the default route to Dashboard
      },
    ],
  },
]);

function Routes() {
  const { user } = useAuth();

  return (
    <AxiosProvider>
      <RouterProvider
        router={user ? authosizedRoute : unAuthosizedRoute}
      ></RouterProvider>
    </AxiosProvider>
  );
}

export default Routes;
