import { Box, Button, IconButton, MenuItem, useTheme } from "@mui/material";
import { useState } from "react";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { Outlet, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useAuth } from "../Context/authContext";
import { Dashboard } from "@mui/icons-material";
import { tokens } from "../theme";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    setAnchorEl(null);
    navigate("/myaccount");
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
    navigate("/login");
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        boxShadow="0 0px 4px rgba(0, 0, 0, 0.5)"
        backgroundColor="#fff"
        alignItems="center"
        style={{ position: "fixed", top: 0, width: "100%", zIndex: 3 }}
      >
        <Box display={"flex"} flexDirection={"row"}>
          <IconButton size="medium" onClick={() => navigate("/dashboard")}>
            <Dashboard />
          </IconButton>
          <h3 style={{ lineHeight: 0.6 }}>Domain Dashboard</h3>
        </Box>
        {user ? (
          <Box display="flex">
            {/* <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === 'light' ? (
                            <LightModeOutlinedIcon/>
                        ) : (
                            <DarkModeOutlinedIcon/>
                            )
                        }
                    </IconButton> */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="medium"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
                </IconButton>
              </Tooltip>
            </Box>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={() => setAnchorEl(null)}
              onClick={() => setAnchorEl(null)}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleProfile}>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <Box display={"flex"}>
            <Button
              size="small"
              style={{
                color: "white",
                backgroundColor: colors.blueAccent[500],
              }}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </Box>
        )}
      </Box>
      <Box style={{ paddingTop: 70 }}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Topbar;
